<template>
  <div>
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-center text-uppercase">
            Name
          </th>
          <th class="text-center text-uppercase">
            Payment For
          </th>
          <th class="text-center text-uppercase">
            OR Date
          </th>
          <th class="text-center text-uppercase">
            OR #
          </th>
          <th class="text-center text-uppercase">
            Amount
          </th>
          <th class="text-center text-uppercase">
            Description
          </th>
          <th class="text-center text-uppercase">
            Actions
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
          v-for="item in liquidations"
          :key="item.id"
        >
          <td class="text-center" v-if="item.deceased===null">
            {{ '' }}
          </td>
          <td class="text-center" v-else>
            {{ item.deceased.last_name+', '+item.deceased.first_name+' '+item.deceased.middle_name
            }}
          </td>
          <td class="text-center">
            {{ item.particulars.particulars }}
          </td>
          <td class="text-center">
            {{ item.or_date }}
          </td>
          <td class="text-center">
            {{ item.or_no }}
          </td>
          <td class="text-center">
            {{ formatPrice(item.amount) }}
          </td>
          <td class="text-center">
            {{ item.description }}
          </td>
          <td class="text-center">
            <div>
              <v-icon
                v-if="!is_deleting"
                class="mr-2"
                color="error"
                @click="delete_payment(liquidations.map(function(x) {return x.id; }).indexOf(item.id))"
              >
                {{icons.mdiDelete}}
              </v-icon>
              <v-progress-circular color="info" indeterminate
                                   v-else></v-progress-circular>
            </div>
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>

    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiDelete} from "@mdi/js";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from "@/components/dialogs/notifications_dialog/Snackbar";

  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiDelete,
        },
        is_deleting: false,
      }
    },
    props: {
      liquidations: Array,
    },
    computed: {
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('chapel_fund_liquidation', ['delete_chapel_fund_liquidation']),
      delete_payment(index) {
        this.is_deleting = true
        var data = this.liquidations[index]
        this.delete_chapel_fund_liquidation({
          id: data.id,
          chapel_fund_id: data.chapel_fund_id,
          restored_amount: data.amount,
        })
          .then(response => {
            this.change_snackbar({
              show: true,
              color: 'error',
              text: response.data,
            })
            this.is_deleting = false
            this.$emit('data', true)
          })
          .catch(error => {
            console.log(error)
          })
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
    },
  }
</script>
