<template>
  <v-card
    flat
    dense
  >
    <v-toolbar dense dark color="primary">
      <v-toolbar-title><h5 class="font-weight-light">CREDITED CHAPEL FUND</h5>
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-row>
        <v-col
          md="12"
          cols="12"
        ></v-col>
        <v-col
          md="12"
          cols="12"
        >
          <v-text-field
            v-model="data.date_credited"
            label="Date Credited"
            dense
            outlined
            readonly
          ></v-text-field>
          <v-text-field
            v-model="data.credited_to"
            label="Credited To"
            dense
            outlined
            readonly
          ></v-text-field>
          <v-text-field
            v-model="data.voucher_no"
            label="Voucher #"
            dense
            outlined
            readonly
          ></v-text-field>
          <v-text-field
            v-model="data.amount"
            label="Credited Amount"
            dense
            outlined
            readonly
          ></v-text-field>
          <v-text-field
            v-model="data.balance"
            label="Balance"
            dense
            outlined
            readonly
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    props: {
      data: Object,
    },
  }
</script>
