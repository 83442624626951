<template>
  <div>
    <v-card flat dense>
      <v-form ref="form" lazy-validation>
        <v-flex xs12>
          <v-layout row wrap align-center>
            <v-flex xs12 md6>
              <v-text-field
                v-model="or_date"
                type="date"
                label="OR Date"
                class="mx-2"
                required
                :rules="rules.default_max_25_character_and_no_empty_rule"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 md6>
              <v-text-field
                v-model="or_no"
                label="OR No."
                class="mx-2"
                required
                :rules="rules.default_max_45_character_and_no_empty_rule"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 md12>
              <v-checkbox v-model="can_double_pay" label="Is Double Pay" hide-details
                          class="mb-4" readonly></v-checkbox>
            </v-flex>

            <v-flex xs12 md4 v-if="!is_customize_amount">
              <v-text-field v-model="new_amount" label="Amount" class="mx-2" required
                            readonly></v-text-field>
            </v-flex>
            <v-flex xs12 md4 v-else>
              <v-text-field
                v-model="new_amount"
                :min="0"
                type="number"
                label="Amount"
                class="mx-2"
                required
              ></v-text-field>
            </v-flex>

            <v-flex xs12 md4>
              <v-text-field
                v-model="payee"
                label="Payee"
                class="mx-2"
                required
                :rules="rules.default_max_75_character_and_no_empty_rule"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 md12 v-if="!is_have_deceased">
              <v-text-field
                v-model="description"
                label="Description"
                class="mx-2"
                required
                :rules="rules.default_max_255_character_and_no_empty_rule"
              ></v-text-field>
            </v-flex>
            <!-- alert -->
            <v-col cols="12" v-show="alert">
              <v-alert color="warning" text class="mb-0">
                <div class="d-flex align-start">
                  <v-icon color="warning">
                    {{ icons.mdiAlertOutline }}
                  </v-icon>

                  <div class="ms-3">
                    <p class="text-base font-weight-medium mb-1">
                      {{ alert_message }}
                    </p>
                  </div>
                </div>
              </v-alert>
            </v-col>

            <v-col cols="12">
              <v-btn color="primary" class="me-3 mt-4" @click="save_payments" v-if="!saving"> Save
                changes
              </v-btn>
              <v-progress-circular :size="50" :width="5" color="primary" indeterminate
                                   v-else></v-progress-circular>
            </v-col>
          </v-layout>
        </v-flex>
      </v-form>
    </v-card>
    <snack-bar-dialog :snackbar_flag="this.snackbar" :color="this.snackbar_color"
                      :snackbar_text="this.snackbar_text"/>
  </div>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex'
  import {mdiAlertOutline, mdiCloudUploadOutline} from '@mdi/js'
  import moment from 'moment'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  export default {
    props: {
      is_have_deceased: Boolean,
      amount: Number,
      selected_search_items: Object,
      chapel_fund_id: Number,
      particulars_id: Number,
      particulars: String,
    },
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        is_double_pay: false,
        can_double_pay: false,
        is_customize_amount: false,
        new_amount: 0,
        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
        },
      }
    },
    data() {
      return {
        encoded_id: 0,

        saving: false,
        alert: false,
        alert_message: '',

        or_date: '',
        or_no: '',


        payee: '',
        description: '',
      }
    },
    watch: {
      selected_search_items: function () {
        this.get_details()
      },
      particulars_id: function () {
        this.can_double_pay = false
        this.new_amount = this.amount
      }
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['branch_id', 'month_of', 'user_id']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('chapel_fund_liquidation', ['save_chapel_fund_liquidation']),
      ...mapActions('chapel_fund_particulars', ['get_amount_chapel_fund_particulars']),
      async get_details() {
        if (this.particulars != 'Tarp' && this.particulars != 'Flower' && this.particulars != 'Others' && this.particulars != 'Office Supply'
          && this.particulars != 'Chapel Supply' && this.particulars != 'Return Fund' && this.particulars != 'Fare' && this.particulars != 'Freight'&& this.particulars != 'Laundry Curtains'
          && this.particulars != 'Water'&& this.particulars != 'Chapel Equipment'&& this.particulars != 'Restoration') {
          if (Object.keys(this.selected_search_items).length > 0) {
            this.is_customize_amount = false
            await this.get_amount_chapel_fund_particulars({
              particulars_id: this.particulars_id,
              casket_type: this.selected_search_items.data.casket_type,
            })
              .then(response => {
                this.new_amount = response.data
              })
            this.can_double_pay = parseFloat(this.selected_search_items.data.kilometer) >= 50 && this.particulars != 'Embalmer' && this.particulars != 'Undertaker'
              && this.particulars != 'Embalmer allowance' && this.particulars != 'Undertaker allowance'
            if (this.can_double_pay) {
              this.new_amount = this.new_amount * 2
            }
          }
        } else {
          this.is_customize_amount = true
        }
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      save_payments() {
        this.saving = true
        this.alert = false
        if (this.$refs.form.validate()) {
          var proceed = true
          var total_amount_to = this.new_amount
          if (this.balance <= 0 || this.balance - total_amount_to < -100) {
            proceed = false
            this.alert = true
            this.alert_message = 'Please Load Deposit Slip First'
            this.saving = false
          }
          if (Object.keys(this.selected_search_items).length === 0 && this.is_have_deceased) {
            proceed = false
            this.alert = true
            this.alert_message = 'Please Select Deceased First!'
            this.saving = false
          }
          if (parseFloat(this.new_amount) < 1) {
            proceed = false
            this.alert = true
            this.alert_message = 'Please Check the Amount!'
            this.saving = false
          }
          //if no error above then proceed
          if (proceed) {
            this.save_chapel_fund_liquidation({
              branch_id: this.branch_id,
              deceased_client_id: this.selected_search_items.id,
              chapel_fund_id: this.chapel_fund_id,
              particulars_id: this.particulars_id,
              payee: this.payee,
              or_date: moment(this.or_date, 'YYYY-MM-DD').format('MMMM D, YYYY'),
              or_no: this.or_no,
              amount: this.new_amount,
              description: this.description,
            })
              .then(response => {
                var color = 'success'
                if (response.status === 201) {
                  color = 'error'
                }
                this.change_snackbar({
                  show: true,
                  color: color,
                  text: response.data,
                })
                this.saving = false
                this.description = ''
                this.$emit('data', true)
              })
              .catch(error => {
                this.alert = true
                this.alert_message = error
                this.saving = false
              })
          }
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
        }
      },
    },
  }
</script>
