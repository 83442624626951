<template>
  <div>
    <v-row dense>
      <v-col md="3" cols="12">
        <deposit-slip-info :data="chapel_fund_items"></deposit-slip-info>
      </v-col>
      <v-col md="9" cols="12" v-if="Object.keys(chapel_fund_items).length > 0">
        <v-card flat dense>
          <v-toolbar dense dark color="primary">
            <v-toolbar-title><h5 class="font-weight-light">LIQUIDATION SECTION</h5>
            </v-toolbar-title>
            &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
            <strong>
              <h3 class="font-weight-light">Overall Balance: {{ formatPrice(balance_format) }}</h3>
            </strong>
          </v-toolbar>
          <v-card-text>
            <v-col md="12" cols="12"></v-col>
            <v-row dense>
              <v-col md="5" cols="12">
                <v-select
                  class="mx-2"
                  :items="chapel_fund_particulars_items"
                  item-value="particulars"
                  item-text="particulars"
                  label="Particulars"
                  required
                  :rules="rules.combobox_rule"
                  v-model="particulars"
                  dense
                  @change="reset"
                ></v-select>
              </v-col>
              <v-col md="7" cols="12" v-if="is_have_deceased">
                <v-combobox
                  class="mx-2"
                  :append-icon="icons.mdiAccountSearchOutline"
                  v-model="search"
                  @keyup="searching($event.target.value)"
                  @change="get_search_items_info"
                  :items="search_items"
                  item-value="id"
                  item-text="name"
                  placeholder="Search by (Lastname/Firstname/CMF #/Account #)"
                  ref="memberCombobox"
                  dense
                ></v-combobox>
              </v-col>
              <v-col md="4" cols="12" v-if="is_have_deceased">
                <v-text-field v-model="id_no" label="ID #" dense outlined readonly></v-text-field>
              </v-col>
              <v-col md="8" cols="12" v-if="is_have_deceased">
                <v-text-field v-model="name" label="Name" dense outlined readonly></v-text-field>
              </v-col>
              <v-col md="12" cols="12">
                <payment-sections v-on:data="on_save" :is_have_deceased="is_have_deceased"
                                  :is_customize_amount="is_customize_amount"
                                  :selected_search_items="selected_search_items"
                                  :amount="particulars_amount" :chapel_fund_id="chapel_fund_id"
                                  :particulars="particulars"
                                  :particulars_id="particulars_id"></payment-sections>
              </v-col>
              <v-col md="12" cols="12">
                <h2>Liquidations Data</h2>
                <payments-data v-on:data="on_save"
                               :liquidations="chapel_fund_items.liquidations"></payments-data>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import {mdiAccountSearchOutline} from '@mdi/js'
  import {mapGetters, mapActions} from 'vuex'
  import DepositSlipInfo
    from '@/views/liquidations/chapel_fund_liquidations/ChapelFundLiquidationDepositSlipInfo'
  import PaymentSections
    from '@/views/liquidations/chapel_fund_liquidations/ChapelFundLiquidationSections'
  import PaymentsData from '@/views/liquidations/chapel_fund_liquidations/ChapelFundLiquidationData'

  const initialState = () => {
    return {
      payment_for_data: [],

      id_no: '',
      name: '',

      search: '',
      search_member: '',
      search_items: [],
      selected_search_items: {},

      balance: 0,
      balance_format: '0',

      chapel_fund_items: {},
      particulars: '',
      chapel_fund_particulars_items: [],
      is_have_deceased: false,
      is_customize_amount: false,

      particulars_amount: 0,

      chapel_fund_id: 0,
      particulars_id: 0,
    }
  }

  export default {
    components: {
      DepositSlipInfo,
      PaymentSections,
      PaymentsData,
    },
    setup() {
      return {
        icons: {
          mdiAccountSearchOutline,
        },
      }
    },
    data() {
      return initialState()
    },
    created() {
      this.initialize_data()
    },
    watch: {
      search_member: function () {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          if (this.search_member === '') {
            this.search_items = []
          } else {
            this.searching_search_items(this.search_member)
          }
        }, 500)
      },
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['branch_id']),
      ...mapGetters('authentication', ['user_id']),
    },
    methods: {
      ...mapActions('deceased_client', ['search_deceased_client']),
      ...mapActions('chapel_fund', ['get_latest_chapel_fund']),
      ...mapActions('chapel_fund_particulars', ['get_chapel_fund_particulars']),
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      initialize_data() {
        const data = new FormData()
        data.append('branch_id', this.branch_id)
        this.get_latest_chapel_fund(data)
          .then(response => {
            this.chapel_fund_items = Object.keys(response.data).length === 0 ? {} : response.data
            this.balance_format = this.chapel_fund_items.balance
            this.chapel_fund_id = this.chapel_fund_items.id
          })
          .catch(error => {
            console.log(error)
          })
        this.get_chapel_fund_particulars(data)
          .then(response => {
            this.chapel_fund_particulars_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      reset() {
        this.search = ''
        this.search_items = []
        this.search_member = ''
        this.selected_search_items = {}
        this.id_no = ''
        this.name = ''
        if (this.chapel_fund_particulars_items.length > 0) {
          this.is_customize_amount =
            parseInt(this.chapel_fund_particulars_items[
              this.chapel_fund_particulars_items
                .map(function (x) {
                  return x.particulars
                })
                .indexOf(this.particulars)
              ].amount) === 0
              ? true
              : false

          this.is_have_deceased =
            this.chapel_fund_particulars_items[
              this.chapel_fund_particulars_items
                .map(function (x) {
                  return x.particulars
                })
                .indexOf(this.particulars)
              ].is_have_deceased === 1
              ? true
              : false
          this.particulars_amount = parseFloat(this.chapel_fund_particulars_items[
            this.chapel_fund_particulars_items
              .map(function (x) {
                return x.particulars
              })
              .indexOf(this.particulars)
            ].amount)
          this.particulars_id = this.chapel_fund_particulars_items[
            this.chapel_fund_particulars_items
              .map(function (x) {
                return x.particulars
              })
              .indexOf(this.particulars)
            ].id
        }
      }
      ,
      on_save(value) {
        if (value) {
          // Object.assign(this.$data, initialState())
          this.initialize_data()
        }
      }
      ,
      searching(value) {
        this.search_member = value
      }
      ,
      searching_search_items(value) {
        const data = new FormData()
        data.append('branch_id', this.branch_id)
        data.append('search_word', value)
        this.search_deceased_client(data).then(response => {
          this.search_items = response.data
        })
      }
      ,
      get_search_items_info() {
        if (this.search_items.length > 0) {
          var index = this.search_items
            .map(function (x) {
              return x.id
            })
            .indexOf(this.search.id)
          if (index != -1) {
            this.selected_search_items = this.search_items[index]
            this.id_no = this.selected_search_items.id_no
            this.name = this.selected_search_items.name
          } else {
            this.id_no = ''
            this.name = ''
          }
        } else {
          this.id_no = ''
          this.name = ''
        }
      }
      ,
    },
  }
</script>
